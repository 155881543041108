import { Home, WorkDetail } from '../containers';

export const routes = [
  {
    title: 'Home',
    path: '/',
    component: <Home />,
    protected: true,
  },
  {
    title: 'Work Detail',
    path: '/:slug',
    component: <WorkDetail />,
    protected: true,
  },
];
