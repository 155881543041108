import BookStore1 from '../assets/img/works/bookstore/bookstore-1.png';
import BookStore2 from '../assets/img/works/bookstore/bookstore-2.png';
import BookStore3 from '../assets/img/works/bookstore/bookstore-3.png';

import FemiApp1 from '../assets/img/works/femiapp/femiapp-1.png';
import FemiApp2 from '../assets/img/works/femiapp/femiapp-2.png';
import FemiApp3 from '../assets/img/works/femiapp/femiapp-3.png';
import FemiApp4 from '../assets/img/works/femiapp/femiapp-4.png';
import FemiApp5 from '../assets/img/works/femiapp/femiapp-5.png';
import FemiApp6 from '../assets/img/works/femiapp/femiapp-6.png';
import FemiApp7 from '../assets/img/works/femiapp/femiapp-7.png';
import FemiApp8 from '../assets/img/works/femiapp/femiapp-8.png';
import FemiApp9 from '../assets/img/works/femiapp/femiapp-9.png';
import FemiApp10 from '../assets/img/works/femiapp/femiapp-10.png';
import FemiApp11 from '../assets/img/works/femiapp/femiapp-11.png';
import FemiApp12 from '../assets/img/works/femiapp/femiapp-12.png';
import FemiApp13 from '../assets/img/works/femiapp/femiapp-13.png';
import FemiApp14 from '../assets/img/works/femiapp/femiapp-14.png';
import FemiApp15 from '../assets/img/works/femiapp/femiapp-15.png';
import FemiApp16 from '../assets/img/works/femiapp/femiapp-16.png';
import FemiApp17 from '../assets/img/works/femiapp/femiapp-17.png';
import FemiApp18 from '../assets/img/works/femiapp/femiapp-18.png';
import FemiApp19 from '../assets/img/works/femiapp/femiapp-19.png';
import FemiApp20 from '../assets/img/works/femiapp/femiapp-20.png';
import FemiApp21 from '../assets/img/works/femiapp/femiapp-21.png';

import Findbud1 from '../assets/img/works/findbud/findbud-1.png';
import Findbud2 from '../assets/img/works/findbud/findbud-2.png';
import Findbud3 from '../assets/img/works/findbud/findbud-3.png';
import Findbud4 from '../assets/img/works/findbud/findbud-4.png';
import Findbud5 from '../assets/img/works/findbud/findbud-5.png';
import Findbud6 from '../assets/img/works/findbud/findbud-6.png';
import Findbud7 from '../assets/img/works/findbud/findbud-7.png';
import Findbud8 from '../assets/img/works/findbud/findbud-8.png';
import Findbud9 from '../assets/img/works/findbud/findbud-9.png';
import Findbud10 from '../assets/img/works/findbud/findbud-10.png';
import Findbud11 from '../assets/img/works/findbud/findbud-11.png';
import Findbud12 from '../assets/img/works/findbud/findbud-12.png';
import Findbud13 from '../assets/img/works/findbud/findbud-13.png';
import Findbud14 from '../assets/img/works/findbud/findbud-14.png';
import Findbud15 from '../assets/img/works/findbud/findbud-15.png';
import Findbud16 from '../assets/img/works/findbud/findbud-16.png';
import Findbud17 from '../assets/img/works/findbud/findbud-17.png';
import Findbud18 from '../assets/img/works/findbud/findbud-18.png';
import Findbud19 from '../assets/img/works/findbud/findbud-19.png';
import Findbud20 from '../assets/img/works/findbud/findbud-20.png';
import Findbud21 from '../assets/img/works/findbud/findbud-21.png';
import Findbud22 from '../assets/img/works/findbud/findbud-22.png';
import Findbud23 from '../assets/img/works/findbud/findbud-23.png';

import Yemekye1 from '../assets/img/works/yemekye/yemekye-1.png';
import Yemekye2 from '../assets/img/works/yemekye/yemekye-2.png';
import Yemekye3 from '../assets/img/works/yemekye/yemekye-3.png';
import Yemekye4 from '../assets/img/works/yemekye/yemekye-4.png';
import Yemekye5 from '../assets/img/works/yemekye/yemekye-5.png';
import Yemekye6 from '../assets/img/works/yemekye/yemekye-6.png';
import Yemekye7 from '../assets/img/works/yemekye/yemekye-7.png';
import Yemekye8 from '../assets/img/works/yemekye/yemekye-8.png';
import Yemekye9 from '../assets/img/works/yemekye/yemekye-9.png';
import Yemekye10 from '../assets/img/works/yemekye/yemekye-10.png';
import Yemekye11 from '../assets/img/works/yemekye/yemekye-11.png';
import Yemekye12 from '../assets/img/works/yemekye/yemekye-12.png';
import Yemekye13 from '../assets/img/works/yemekye/yemekye-13.png';
import Yemekye14 from '../assets/img/works/yemekye/yemekye-14.png';
import Yemekye15 from '../assets/img/works/yemekye/yemekye-15.png';
import Yemekye16 from '../assets/img/works/yemekye/yemekye-16.png';
import Yemekye17 from '../assets/img/works/yemekye/yemekye-17.png';
import Yemekye19 from '../assets/img/works/yemekye/yemekye-19.png';
import Yemekye21 from '../assets/img/works/yemekye/yemekye-21.png';
import Yemekye22 from '../assets/img/works/yemekye/yemekye-22.png';

import Linked1 from '../assets/img/works/linked/linked-1.png';
import Linked2 from '../assets/img/works/linked/linked-2.png';
import Linked3 from '../assets/img/works/linked/linked-3.png';

import Sushimu1 from '../assets/img/works/sushimu/sushimu-1.png';
import Sushimu2 from '../assets/img/works/sushimu/sushimu-2.png';
import Sushimu3 from '../assets/img/works/sushimu/sushimu-3.png';

import Mimarlik1 from '../assets/img/works/mimarlik/mimarlik-1.png';
import Mimarlik2 from '../assets/img/works/mimarlik/mimarlik-2.png';
import Mimarlik3 from '../assets/img/works/mimarlik/mimarlik-3.png';

import HarryPotter1 from '../assets/img/works/harry-potter/harry-potter-1.png';
import HarryPotter2 from '../assets/img/works/harry-potter/harry-potter-2.png';
import HarryPotter3 from '../assets/img/works/harry-potter/harry-potter-3.png';

import LeonardoDaVinci1 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-1.png';
import LeonardoDaVinci2 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-2.png';
import LeonardoDaVinci3 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-3.png';
import LeonardoDaVinci4 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-4.png';
import LeonardoDaVinci5 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-5.png';
import LeonardoDaVinci6 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-6.png';
import LeonardoDaVinci7 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-7.png';
import LeonardoDaVinci8 from '../assets/img/works/leonardo-da-vinci/leonardo-da-vinci-8.png';

export const WorkData = [
  {
    color: '#EBDF4B',
    stroke: '#FFF580',
    title: 'BookStore',
    subTitle: 'UI / UX Design',
    date: '28.02.2022',
    workType: 'WEB',
    slug: 'book-store',
    mode: 'dark',
    workImage: [
      {
        type: 'full',
        image: BookStore1,
      },
      {
        type: 'full',
        image: BookStore2,
      },
      {
        type: 'full',
        image: BookStore3,
      },
    ],
  },
  {
    color: '#F20587',
    stroke: '#F483C1',
    title: 'FINDBUD',
    subTitle: 'UI / UX Design',
    date: '15.01.2022',
    workType: 'APP / WEB',
    slug: 'findbud',
    mode: 'light',
    workImage: [
      {
        type: 'full',
        image: Findbud1,
      },
      {
        type: 'full',
        image: Findbud2,
      },
      {
        type: 'leftColumn',
        image: Findbud3,
      },
      {
        type: 'rightColumn',
        image: Findbud4,
      },
      {
        type: 'leftColumn',
        image: Findbud5,
      },
      {
        type: 'rightColumn',
        image: Findbud6,
      },
      {
        type: 'leftColumn',
        image: Findbud7,
      },
      {
        type: 'rightColumn',
        image: Findbud8,
      },
      {
        type: 'leftColumn',
        image: Findbud9,
      },
      {
        type: 'rightColumn',
        image: Findbud10,
      },
      {
        type: 'leftColumn',
        image: Findbud11,
      },
      {
        type: 'rightColumn',
        image: Findbud12,
      },
      {
        type: 'leftColumn',
        image: Findbud13,
      },
      {
        type: 'rightColumn',
        image: Findbud14,
      },
      {
        type: 'leftColumn',
        image: Findbud15,
      },
      {
        type: 'rightColumn',
        image: Findbud16,
      },
      {
        type: 'leftColumn',
        image: Findbud17,
      },
      {
        type: 'rightColumn',
        image: Findbud18,
      },
      {
        type: 'leftColumn',
        image: Findbud19,
      },

      {
        type: 'leftColumn',
        image: Findbud20,
      },
      {
        type: 'rightColumn',
        image: Findbud21,
      },
      {
        type: 'rightColumn',
        image: Findbud22,
      },
      {
        type: 'full',
        image: Findbud23,
      },
    ],
  },
  {
    color: '#15C15D',
    stroke: '#0ED260',
    title: 'LİNKED',
    subTitle: 'UI / UX Design',
    date: '27.02.2022',
    workType: 'WEB',
    slug: 'linked',
    mode: 'light',
    workImage: [
      {
        type: 'full',
        image: Linked1,
      },
      {
        type: 'full',
        image: Linked2,
      },
      {
        type: 'full',
        image: Linked3,
      },
    ],
  },
  {
    color: '#F22929',
    stroke: '#F47979',
    title: 'YEMEKYE',
    subTitle: 'UI / UX Design',
    date: '21.02.2022',
    workType: 'APP',
    slug: 'yemekye',
    mode: 'light',
    workImage: [
      {
        type: 'full',
        image: Yemekye1,
      },
      {
        type: 'leftColumn',
        image: Yemekye2,
      },
      {
        type: 'rightColumn',
        image: Yemekye3,
      },
      {
        type: 'leftColumn',
        image: Yemekye4,
      },
      {
        type: 'rightColumn',
        image: Yemekye5,
      },
      {
        type: 'leftColumn',
        image: Yemekye6,
      },
      {
        type: 'rightColumn',
        image: Yemekye7,
      },
      {
        type: 'leftColumn',
        image: Yemekye8,
      },
      {
        type: 'rightColumn',
        image: Yemekye9,
      },
      {
        type: 'leftColumn',
        image: Yemekye10,
      },
      {
        type: 'rightColumn',
        image: Yemekye11,
      },
      {
        type: 'leftColumn',
        image: Yemekye12,
      },
      {
        type: 'rightColumn',
        image: Yemekye13,
      },
      {
        type: 'leftColumn',
        image: Yemekye14,
      },
      {
        type: 'rightColumn',
        image: Yemekye15,
      },
      {
        type: 'leftColumn',
        image: Yemekye16,
      },
      {
        type: 'rightColumn',
        image: Yemekye17,
      },

      {
        type: 'leftColumn',
        image: Yemekye19,
      },

      {
        type: 'rightColumn',
        image: Yemekye21,
      },
      {
        type: 'full',
        image: Yemekye22,
      },
    ],
  },
  {
    color: '#D2E0C9',
    stroke: '#EDEDED',
    title: 'SUSHIMU',
    subTitle: 'UI / UX Design',
    date: '02.03.2022',
    workType: 'WEB',
    slug: 'sushimu',
    mode: 'dark',
    workImage: [
      {
        type: 'full',
        image: Sushimu1,
      },
      {
        type: 'full',
        image: Sushimu2,
      },
      {
        type: 'full',
        image: Sushimu3,
      },
    ],
  },
  {
    color: '#EDF3F8',
    stroke: '#FFFFFF',
    title: 'MİMARLIK',
    subTitle: 'UI / UX Design',
    date: '03.03.2022',
    workType: 'WEB',
    slug: 'mimarlik',
    mode: 'dark',
    workImage: [
      {
        type: 'full',
        image: Mimarlik1,
      },
      {
        type: 'full',
        image: Mimarlik2,
      },
      {
        type: 'full',
        image: Mimarlik3,
      },
    ],
  },
  {
    color: '#4F4538',
    stroke: '#836D1E',
    title: 'HARRY POTTER',
    subTitle: 'UI / UX Design',
    date: '04.03.2022',
    workType: 'WEB',
    slug: 'harry-potter',
    mode: 'light',
    workImage: [
      {
        type: 'full',
        image: HarryPotter1,
      },
      {
        type: 'full',
        image: HarryPotter2,
      },
      {
        type: 'full',
        image: HarryPotter3,
      },
    ],
  },
  {
    color: '#C798CF',
    stroke: '#F4B1FF',
    title: 'FEMIAPP',
    subTitle: 'UI / UX Design',
    date: '10.01.2022',
    workType: 'APP',
    slug: 'femiapp',
    mode: 'light',
    workImage: [
      {
        type: 'noBorder',
        image: FemiApp1,
      },
      {
        type: 'leftColumn',
        image: FemiApp2,
      },
      {
        type: 'rightColumn',
        image: FemiApp3,
      },
      {
        type: 'leftColumn',
        image: FemiApp4,
      },
      {
        type: 'rightColumn',
        image: FemiApp5,
      },
      {
        type: 'leftColumn',
        image: FemiApp6,
      },
      {
        type: 'rightColumn',
        image: FemiApp7,
      },
      {
        type: 'leftColumn',
        image: FemiApp8,
      },
      {
        type: 'rightColumn',
        image: FemiApp9,
      },
      {
        type: 'leftColumn',
        image: FemiApp10,
      },
      {
        type: 'rightColumn',
        image: FemiApp11,
      },
      {
        type: 'leftColumn',
        image: FemiApp12,
      },
      {
        type: 'rightColumn',
        image: FemiApp13,
      },
      {
        type: 'leftColumn',
        image: FemiApp14,
      },
      {
        type: 'rightColumn',
        image: FemiApp15,
      },
      {
        type: 'leftColumn',
        image: FemiApp16,
      },
      {
        type: 'rightColumn',
        image: FemiApp17,
      },
      {
        type: 'leftColumn',
        image: FemiApp18,
      },
      {
        type: 'leftColumn',
        image: FemiApp19,
      },
      {
        type: 'leftColumn',
        image: FemiApp20,
      },
      {
        type: 'full',
        image: FemiApp21,
      },
    ],
  },
  {
    color: '#D9CCB4',
    stroke: '#FFF3DE',
    title: 'LEONARDO DA VINCI',
    subTitle: 'UI / UX Design',
    date: '05.03.2022',
    workType: 'WEB',
    slug: 'leonardo-da-vinci',
    mode: 'dark',
    workImage: [
      {
        type: 'full',
        image: LeonardoDaVinci1,
      },
      {
        type: 'full',
        image: LeonardoDaVinci2,
      },
      {
        type: 'full',
        image: LeonardoDaVinci3,
      },
      {
        type: 'full',
        image: LeonardoDaVinci4,
      },
      {
        type: 'full',
        image: LeonardoDaVinci5,
      },
      {
        type: 'full',
        image: LeonardoDaVinci6,
      },
      {
        type: 'full',
        image: LeonardoDaVinci7,
      },
      {
        type: 'full',
        image: LeonardoDaVinci8,
      },
    ],
  },
];
