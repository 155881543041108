import Logo from '../../assets/img/logo/big-logo-dark.png';
import PhotoOriginal from '../../assets/img/logo/photo-original.png';
import PhotoPixel from '../../assets/img/logo/photo-pixel.png';
import { Spring, animated } from 'react-spring';
import style from './home.module.scss';
import { WorkData } from '../../data/WorkData';
import { useState } from 'react';
import useWindowDimensions from '../../helpers/windowWidth';
import { useNavigate } from 'react-router-dom';
import {
  DribbleIcon,
  InstagramIcon,
  BehanceIcon,
  HamburgerIcon,
  CloseIcon,
} from '../../assets/icon';

const MobileMenu = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className={style.hamburgerMenu}>
        <button
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          {isOpen ? <CloseIcon isLight /> : <HamburgerIcon />}
        </button>
      </div>
      {isOpen ? (
        <div className={style.mobileHeader}>
          <div className={style.content}>
            <a
              className={style.mail}
              href="mailto:hello@mukaddescavusoglu.com"
              target={'_blank'}
              // style={{
              //   color: isLight ? 'white' : 'black',
              // }}
              rel="noreferrer"
            >
              hello@mukaddescavusoglu.com
            </a>
            <a
              className={style.social}
              target={'_blank'}
              href="https://www.behance.net/mukaddesavuolu"
              rel="noreferrer"
            >
              <BehanceIcon isLight />
            </a>
            <a
              className={style.social}
              target={'_blank'}
              href="https://dribbble.com/mkdscvsglu"
              rel="noreferrer"
            >
              <DribbleIcon isLight />
            </a>
            <a
              className={style.social}
              target={'_blank'}
              href="https://www.instagram.com/mkddscvsgluui/"
              rel="noreferrer"
            >
              <InstagramIcon isLight />
            </a>
          </div>
        </div>
      ) : null}
    </>
  );
};

const TranslationComponent = ({ color }) => {
  const { width } = useWindowDimensions();
  console.log(width);
  return (
    <Spring
      from={{ width: '50px' }}
      to={{ width: `${width}px` }}
      config={{
        duration: 500,
      }}
    >
      {styles => (
        <animated.div
          style={{
            ...styles,
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100vh',
            background: color,
            zIndex: 10,
          }}
        />
      )}
    </Spring>
  );
};

const Header = ({ isLight }) => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      config={{
        duration: 100,
      }}
    >
      {styles => (
        <animated.div
          style={{
            ...styles,
          }}
          className={style.header}
        >
          <div className={style.right}>
            <a
              className={style.mail}
              href="mailto:hello@mukaddescavusoglu.com"
              target={'_blank'}
              style={{
                color: isLight ? 'white' : 'black',
              }}
              rel="noreferrer"
            >
              hello@mukaddescavusoglu.com
            </a>
            <a
              target={'_blank'}
              href="https://www.behance.net/mukaddesavuolu"
              rel="noreferrer"
            >
              <BehanceIcon isLight={isLight} />
            </a>
            <a
              target={'_blank'}
              href="https://dribbble.com/mkdscvsglu"
              rel="noreferrer"
            >
              <DribbleIcon isLight={isLight} />
            </a>
            <a
              target={'_blank'}
              href="https://www.instagram.com/mkddscvsgluui/"
              rel="noreferrer"
            >
              <InstagramIcon isLight={isLight} />
            </a>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [pieceIndex, setPieceIndex] = useState();
  const [selectedPiece, setSelectedPiece] = useState();
  const [isPhoto, setPhoto] = useState(false);
  const { width, height } = useWindowDimensions();
  return (
    <>
      {width > 900 ? <Header /> : <MobileMenu />}
      {selectedPiece ? <TranslationComponent color={selectedPiece} /> : null}
      <div className={style.home}>
        <div className={style.content}>
          <div
            onMouseEnter={() => setPhoto(true)}
            onMouseLeave={() => setPhoto(false)}
            className={style.wrapper}
          >
            <div className={style.photoWrapper}>
              <img
                className={style.original}
                src={PhotoOriginal}
                alt="original"
              />
              <Spring
                from={{
                  opacity: 0,
                  left: '400px',
                  transform: 'rotate(60deg)',
                }}
                to={{
                  opacity: isPhoto ? 1 : 0,
                  left: isPhoto ? `${height / 12}px` : '400px',
                  transform: isPhoto ? 'rotate(15deg)' : 'rotate(60deg)',
                }}
              >
                {styles => (
                  <animated.img
                    className={style.photoPixel}
                    src={PhotoPixel}
                    style={styles}
                    alt="pixel"
                  />
                )}
              </Spring>
            </div>
            <div className={style.info}>
              <h4>Merhaba ben,</h4>
              <img className={style.logo} src={Logo} alt="logo" />
              <p>
                Deneyim ve etkileşime odaklanan bir UI tasarımcıyım. Kullanıcı
                <br />
                ihtiyaçlarını ve iş hedeflerini dengeleyen anlamlı ve dijital
                <br />
                ürünler çıkartmaktan keyif alıyorum
              </p>
            </div>
          </div>
        </div>
        <Spring
          loop
          config={{
            duration: 20000,
          }}
          from={{ transform: 'rotate(0deg)' }}
          to={{ transform: 'rotate(360deg)' }}
        >
          {styles => (
            <animated.div
              style={{
                ...styles,
                zIndex: 1,
              }}
              className={style.wheelWrapper}
            >
              <div className={style.wheel}>
                <div className={style.button} />
                {[...WorkData, ...WorkData].map((item, index) => {
                  return (
                    <Spring
                      loop
                      config={{
                        duration: 20000,
                      }}
                      from={{ top: 'unset' }}
                      to={{ top: selectedPiece === index ? '0px' : 'unset' }}
                    >
                      {styles => (
                        <animated.div
                          onMouseEnter={() => setPieceIndex(index)}
                          onMouseLeave={() => setPieceIndex()}
                          onClick={() => {
                            setSelectedPiece(item.color);
                            setTimeout(() => {
                              navigate(`/${item.slug}`);
                            }, 1000);
                          }}
                          style={{
                            ...styles,
                            transform: `rotateZ(${-index * 12}deg) `,
                            background: item.color,
                            borderWidth: 1,
                            borderColor: item.stroke,
                          }}
                          className={style.wheelPiece}
                        />
                      )}
                    </Spring>
                  );
                })}
              </div>
            </animated.div>
          )}
        </Spring>
        <Spring
          loop
          config={{
            duration: 20000,
          }}
          from={{ transform: 'rotate(0deg)' }}
          to={{ transform: 'rotate(360deg)' }}
        >
          {styles => (
            <animated.div style={styles} className={`${style.wheelWrapper}`}>
              <div className={`${style.wheel} ${style.wheelBackground}`}>
                {[...WorkData, ...WorkData].map((item, index) => {
                  return (
                    <div
                      style={{
                        transform: `rotateZ(${-index * 12}deg) `,
                        background: item.color,
                        borderWidth: 1,
                        borderColor: item.color,
                        height: index === pieceIndex ? `600px` : '400px',
                        width: index === pieceIndex ? `60px` : '40px',
                      }}
                      className={`${style.wheelPiece} `}
                    />
                  );
                })}
              </div>
            </animated.div>
          )}
        </Spring>
      </div>
    </>
  );
};

export default Home;
