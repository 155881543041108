import style from './workdetail.module.scss';
import { useLocation } from 'react-router-dom';
import { WorkData } from '../../data/WorkData';
import { Spring, animated } from 'react-spring';
import useWindowDimensions from '../../helpers/windowWidth';
import miniLogoWhite from '../../assets/img/logo/mini-logo-white.png';
import miniLogoDark from '../../assets/img/logo/mini-logo-dark.png';
import {
  DribbleIcon,
  InstagramIcon,
  BehanceIcon,
  HamburgerIcon,
  CloseIcon,
} from '../../assets/icon';
import { useState } from 'react';

const MobileMenu = ({ background, isLight }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className={style.hamburgerMenu}>
        <a className={style.logo} href="/">
          <img src={miniLogoDark} alt="logo" />
        </a>

        <button
          onClick={() => {
            setOpen(!isOpen);
          }}
        >
          {isOpen ? <CloseIcon isLight={isLight} /> : <HamburgerIcon />}
        </button>
      </div>
      {isOpen ? (
        <div
          style={{
            background,
          }}
          className={style.mobileHeader}
        >
          <div className={style.content}>
            <a
              className={style.mail}
              href="mailto:hello@mukaddescavusoglu.com"
              target={'_blank'}
              style={{
                color: isLight ? 'white' : 'black',
              }}
              rel="noreferrer"
            >
              hello@mukaddescavusoglu.com
            </a>
            <a
              className={style.social}
              target={'_blank'}
              href="https://www.behance.net/mukaddesavuolu"
              rel="noreferrer"
            >
              <BehanceIcon isLight={isLight} />
            </a>
            <a
              className={style.social}
              target={'_blank'}
              href="https://dribbble.com/mkdscvsglu"
              rel="noreferrer"
            >
              <DribbleIcon isLight={isLight} />
            </a>
            <a
              className={style.social}
              target={'_blank'}
              href="https://www.instagram.com/mkddscvsgluui/"
              rel="noreferrer"
            >
              <InstagramIcon isLight={isLight} />
            </a>
          </div>
        </div>
      ) : null}
    </>
  );
};

const Header = ({ isLight }) => {
  return (
    <Spring
      from={{ opacity: 0 }}
      to={{ opacity: 1 }}
      delay={500}
      config={{
        duration: 500,
      }}
    >
      {styles => (
        <animated.div
          style={{
            ...styles,
          }}
          className={style.header}
        >
          <a href="/">
            <img src={isLight ? miniLogoWhite : miniLogoDark} alt="logo" />
          </a>

          <div className={style.right}>
            <a
              className={style.mail}
              href="mailto:hello@mukaddescavusoglu.com"
              target={'_blank'}
              style={{
                color: isLight ? 'white' : 'black',
              }}
              rel="noreferrer"
            >
              hello@mukaddescavusoglu.com
            </a>
            <a
              target={'_blank'}
              href="https://www.behance.net/mukaddesavuolu"
              rel="noreferrer"
            >
              <BehanceIcon isLight={isLight} />
            </a>
            <a
              target={'_blank'}
              href="https://dribbble.com/mkdscvsglu"
              rel="noreferrer"
            >
              <DribbleIcon isLight={isLight} />
            </a>
            <a
              target={'_blank'}
              href="https://www.instagram.com/mkddscvsgluui/"
              rel="noreferrer"
            >
              <InstagramIcon isLight={isLight} />
            </a>
          </div>
        </animated.div>
      )}
    </Spring>
  );
};

const BottomWork = ({ title, color, isLight, link }) => {
  console.log(isLight);
  return (
    <Spring
      from={{ bottom: '-50px' }}
      to={{ bottom: '0px' }}
      delay={500}
      config={{
        duration: 500,
      }}
    >
      {styles => (
        <animated.a
          href={`/${link}`}
          className={style.bottomWork}
          style={{
            ...styles,
            background: color,
          }}
        >
          <h5
            style={{
              color: isLight === 'light' ? 'white' : 'black',
            }}
          >
            {title}
          </h5>
        </animated.a>
      )}
    </Spring>
  );
};

const WorkDetail = () => {
  const { width, height } = useWindowDimensions();
  const location = useLocation();
  const currentLocation = location.pathname.split('/')[1];
  const currentWork = WorkData.filter(i => i.slug === currentLocation)[0];
  const nextWork =
    WorkData[WorkData.findIndex(i => i.slug === currentLocation) + 1];

  console.log(nextWork);

  return (
    <>
      {width > 900 ? (
        <Header isLight={currentWork.mode === 'light'} />
      ) : (
        <MobileMenu
          background={currentWork.color}
          isLight={currentWork.mode === 'light'}
        />
      )}
      <div className={style.workDetail}>
        <Spring
          from={{ height: `${height}px`, width: width }}
          to={{ height: '512px', width: width }}
          config={{
            duration: 500,
          }}
          className={style.wrapper}
        >
          {styles => (
            <animated.div
              style={{
                ...styles,
                background: currentWork.color,
              }}
              className={style.workHead}
            >
              <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                delay={500}
                config={{
                  duration: 500,
                }}
              >
                {animatonStyle => (
                  <animated.div
                    style={{
                      ...animatonStyle,
                    }}
                    className={`${style.headInfo} ${style[currentWork.mode]}`}
                  >
                    <h1>{currentWork.title}</h1>
                    <div>
                      <h2>{currentWork.subTitle}</h2>
                      <h3>{currentWork.date}</h3>
                    </div>
                    <h4>{currentWork.workType}</h4>
                  </animated.div>
                )}
              </Spring>
            </animated.div>
          )}
        </Spring>

        {currentWork.workImage.map(i => i.type).includes('noBorder') ? (
          <div className={style.noBorder}>
            {currentWork.workImage
              .slice(0, currentWork.workImage.length - 1)
              .filter(i => i.type === 'noBorder')
              .map((item, index) => {
                return (
                  <img
                    className={style[item.type]}
                    src={item.image}
                    alt={`${currentWork.title}-${index}`}
                  />
                );
              })}
          </div>
        ) : null}

        <div className={style.imageWrapper}>
          {currentWork.workImage
            .slice(0, currentWork.workImage.length - 1)
            .filter(i => i.type === 'full')
            .map((item, index) => {
              return (
                <img
                  className={style[item.type]}
                  src={item.image}
                  alt={`${currentWork.title}-${index}`}
                />
              );
            })}
        </div>
        {currentWork.workImage.map(i => i.type).includes('leftColumn') ||
        currentWork.workImage.map(i => i.type).includes('rightColumn') ? (
          <div className={style.columnWrapper}>
            <div className={style.column}>
              {currentWork.workImage
                .filter(i => i.type === 'leftColumn')
                .map((item, index) => {
                  return (
                    <img
                      className={style[item.type]}
                      src={item.image}
                      alt={`${currentWork.title}-${index}`}
                    />
                  );
                })}
            </div>
            <div className={style.column}>
              {currentWork.workImage
                .filter(i => i.type === 'rightColumn')
                .map((item, index) => {
                  return (
                    <img
                      className={style[item.type]}
                      src={item.image}
                      alt={`${currentWork.title}-${index}`}
                    />
                  );
                })}
            </div>
          </div>
        ) : null}
        <div className={style.imageWrapper}>
          <img
            className={style.full}
            src={currentWork.workImage[currentWork.workImage.length - 1].image}
            alt={`${currentWork.title}-bottom`}
          />
        </div>
      </div>
      {nextWork ? (
        <BottomWork
          title={nextWork.title}
          color={nextWork.color}
          isLight={nextWork.mode}
          link={nextWork.slug}
        />
      ) : (
        <BottomWork
          title={WorkData[0].title}
          color={WorkData[0].color}
          isLight={WorkData[0].mode}
          link={WorkData[0].slug}
        />
      )}
    </>
  );
};

export default WorkDetail;
